<template>
<div>
    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-edit fa-md"></i> <span class="h5"> Gestión venta</span>

                </CCardHeader>

                <CCardBody>
                    <div>
                        <b-row>
                            <b-col md="12">
                                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(verificarAccion)">
                                        <b-row>
                                            <b-col md="6">
                                                <validation-provider name="cliente" rules="required" v-slot="{errors}">
                                                    <b-form-group label="Cliente:" class="mb-2">
                                                        <v-select :reduce="listaClientes =>listaClientes.idCliente" label="razonSocial" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosVenta.idCliente , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosVenta.idCliente" :options="listaClientes" @search:blur="blurCliente">
                                                            <!-- <span slot="no-options" class="text-muted">No hay datos que mostrar.<span style="cursor:pointer;color:#321fdb" @click="modalRegistroClientes = true"> Registra aquí</span></span> -->
                                                            <span slot="no-options" class="text-muted">No hay datos que mostrar.<router-link :to="{name: 'Clientes master'}"> Registra aquí</router-link></span>
                                                        </v-select>
                                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="3">
                                                <validation-provider name="N° Venta" :rules="{required: true}" v-slot="validationContext">
                                                    <b-form-group label="N° Venta:" class="mb-2">
                                                        <b-form-input v-model="datosVenta.numeroVenta" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese N° Venta"></b-form-input>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="3">
                                                <validation-provider name="fecha venta" :rules="{required: true}" v-slot="validationContext">
                                                    <b-form-group label="Fecha venta:" class="mb-2">
                                                        <b-form-input v-model="datosVenta.fechaVenta" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="3">
                                                <validation-provider name="fecha inicio contrato" :rules="{required: true}" v-slot="validationContext">
                                                    <b-form-group label="Fecha inicio contrato:" class="mb-2">
                                                        <b-form-input v-model="datosVenta.fechaInicioContrato" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="3">
                                                <validation-provider name="fecha fin contrato" :rules="{required: true}" v-slot="validationContext">
                                                    <b-form-group label="Fecha fin contrato:" class="mb-2">
                                                        <b-form-input v-model="datosVenta.fechaFinContrato" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>

                                            <b-col md="3" class="text-center">
                                                <b-form-group label="Con colaborador:" class="my-2">
                                                    <b-form-radio-group small plain v-model="datosVenta.conColaborador" :options="radioActivo"></b-form-radio-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="3" class="text-center">
                                                <b-form-group label="Aceptada:" class="my-2">
                                                    <b-form-radio-group small plain v-model="datosVenta.activo" :options="radioActivo"></b-form-radio-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="5">
                                                <validation-provider name="observaciones" v-slot="validationContext">
                                                    <b-form-group label="Observaciones:" class="mb-2">
                                                        <b-form-textarea v-model="datosVenta.observaciones" rows="3" max-rows="6" placeholder="Ingrese observaciones" :state="getValidationState(validationContext)"></b-form-textarea>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="3">
                                                <validation-provider name="cantidad de usuarios" :rules="{ required: true }" v-slot="validationContext">
                                                    <b-form-group label="Cantidad de usuarios:">
                                                        <b-form-select :state="getValidationState(validationContext)" v-model="datosVenta.cantidadUsuarios" id="cantidadUsuarios" :options="listaCantidadUsuarios">
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="4" v-if="datosVenta.conColaborador=='2'">
                                                <validation-provider name="colaborador" :rules="{ required: true }" v-slot="validationContext">
                                                    <b-form-group label="Colaborador:">
                                                        <b-form-select :state="getValidationState(validationContext)" v-model="datosVenta.idColaborador" id="colaborador" value-field="idColaborador" text-field="nombres" :options="listaColaboradores">
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="12" class="mt-2">
                                                <div class="mb-2">
                                                    <span>Servicios: </span>
                                                    <b-button @click="agregarFila()" class="float-right" size="sm" variant="dark">
                                                        <i class="fas fa-plus fa-sm"></i><span> Agregar</span>
                                                    </b-button>
                                                </div>
                                                <b-table :items="datosVenta.listaServicios.filter(x => x.estado == '2')" :fields="camposServicios" class="mt-3" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                    <template v-slot:cell(idServicio)="row">
                                                        <validation-provider :name="`servicio${row.index}`" :rules="{ required: true }" v-slot="validationContext">
                                                            <b-form-select :state="getValidationState(validationContext)" value-field="idServicio" @change="pasarDatos(row)" text-field="descripcion" :options="comboServicios" v-model="row.item.idServicio">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </validation-provider>
                                                    </template>
                                                    <template v-slot:cell(coste)="row">
                                                        <b-form-input disabled type="text" v-model="row.item.coste"></b-form-input>
                                                    </template>
                                                    <template v-slot:cell(importeVenta)="row">
                                                        <b-form-input type="text" v-on:input="sumarTotal" v-model.lazy="row.item.importeVenta"></b-form-input>
                                                    </template>
                                                    <template #cell(acciones)="param">
                                                        <b-button @click="quitarFila(param)" v-show="datosVenta.listaServicios.length >= 2" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                        </b-button>
                                                    </template>
                                                </b-table>
                                                <span class="h6 text-muted my-0 float-right">Total: S/. {{datosVenta.total}}</span>

                                            </b-col>
                                            <b-col class="my-2 text-center" md="12">
                                                <b-button :to="{name: 'Ventas master'}" class=" mr-2" variant="dark">
                                                    <i class="fas fa-arrow-left "></i> Volver
                                                </b-button>
                                                <b-button :disabled="disabled" variant="success" type="submit">
                                                    <i class="fas fa-save "></i> Guardar
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                </validation-observer>
                            </b-col>
                        </b-row>
                    </div>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import firebase from 'firebase';
const dbColaborador = firebase.firestore().collection('colaboradores');
const dbVentas = firebase.firestore().collection('ventas');
const dbClientes = firebase.firestore().collection('clientes');
const dbServicios = firebase.firestore().collection('servicios');

export default {
    data() {
        return {

            camposServicios: [{
                    key: "idServicio",
                    label: "Servicio",
                    class: "text-center",
                },
                {
                    key: "coste",
                    label: "Coste (S/.)",
                    class: "text-center",
                },
                {
                    key: "importeVenta",
                    label: "Importe venta (S/.)",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center",
                },
            ],

            datosVenta: {
                idCliente: null,
                fechaVenta: moment().format('YYYY-MM-DD'),
                fechaInicioContrato: moment().format('YYYY-MM-DD'),
                fechaFinContrato: moment().format('YYYY-MM-DD'),
                numeroVenta: '',
                conColaborador: 1,
                idColaborador: null,
                cantidadUsuarios: null,
                observaciones: '',
                total: 0,
                activo: 2,
                estado: 2,
                listaServicios: [{
                    idServicio: null,
                    coste: 0,
                    importeVenta: 0,
                    estado: 2
                }],
            },

            listaClientes: [],
            listaCantidadUsuarios: [{
                    value: 10,
                    text: 10
                },
                {
                    value: 20,
                    text: 20
                },
                {
                    value: 50,
                    text: 50
                }
            ],
            comboServicios: [],
            listaColaboradores: [],
            radioActivo: [{
                    text: 'Si',
                    value: 2
                },
                {
                    text: 'No',
                    value: 1
                },

            ],
            disabled: false,
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        blurCliente() {
            this.computedPrincipalForm.refs.cliente.validate();
        },
        verificarAccion() {
            if (this.$route.params.id) {
                this.modificarVenta()
            } else {
                this.registrarVenta()
            }
        },
        registrarVenta() {
            let me = this;
            me.disabled = true;
            dbVentas.add(me.datosVenta)
                .then(() => {
                    //console.log("Actualizado correctamente");
                    me.cambiarEstadoCliente(me.datosVenta.idCliente);
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.disabled = false;
                    me.$router.push({
                        name: 'Ventas master'
                    })
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        modificarVenta() {
            let me = this;
            me.disabled = true;
            dbVentas.doc(me.datosVenta.idVenta).update({
                    idCliente: me.datosVenta.idCliente,
                    fechaVenta: me.datosVenta.fechaVenta,
                    fechaInicioContrato: me.datosVenta.fechaInicioContrato,
                    fechaFinContrato: me.datosVenta.fechaFinContrato,
                    numeroVenta: me.datosVenta.numeroVenta,
                    conColaborador: me.datosVenta.conColaborador,
                    idColaborador: me.datosVenta.idColaborador,
                    cantidadUsuarios: me.datosVenta.cantidadUsuarios,
                    observaciones: me.datosVenta.observaciones,
                    total: me.datosVenta.total,
                    activo: me.datosVenta.activo,
                    listaServicios: me.datosVenta.listaServicios,
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then(() => {
                    //console.log("Actualizado correctamente");
                    me.cambiarEstadoCliente(me.datosVenta.idCliente);
                    me.swat('success', 'Modificado satisfactoriamente')
                    me.disabled = false;
                    me.$router.push({
                        name: 'Ventas master'
                    })
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        cambiarEstadoCliente(idCliente) {
            dbClientes.doc(idCliente).update({
                    estado: 2,
                })
                .then(() => {})
                .catch((error) => {});
        },
        agregarFila() {
            this.datosVenta.listaServicios.push({
                idServicio: null,
                idDetalleVenta: null,
                coste: 0,
                importeVenta: 0,
                estado: 2
            })
        },
        quitarFila(param) {
            let me = this;
            me.datosVenta.listaServicios.splice(param.index, 1)
            //  me.sumarTotal();

            let total = parseFloat(me.datosVenta.total);
            total -= parseFloat(param.item.importeVenta);
            me.datosVenta.total = total.toFixed(2)
            // let me = this;
            // me.datosVenta.listaServicios.splice(param.index, 1);
        },
        consultarVenta() {
            let me = this;
            let idVenta = this.$route.params.id;
            if (idVenta) {
                dbVentas.doc(idVenta)
                    .onSnapshot((doc) => {
                        me.datosVenta.idVenta = doc.id;
                        me.datosVenta.idCliente = doc.data().idCliente;
                        me.datosVenta.fechaVenta = doc.data().fechaVenta;
                        me.datosVenta.fechaInicioContrato = doc.data().fechaInicioContrato;
                        me.datosVenta.fechaFinContrato = doc.data().fechaFinContrato;
                        me.datosVenta.numeroVenta = doc.data().numeroVenta;
                        me.datosVenta.conColaborador = doc.data().conColaborador;
                        me.datosVenta.idColaborador = doc.data().idColaborador;
                        me.datosVenta.cantidadUsuarios = doc.data().cantidadUsuarios;
                        me.datosVenta.observaciones = doc.data().observaciones;
                        me.datosVenta.total = doc.data().total;
                        me.datosVenta.activo = doc.data().activo;
                        me.datosVenta.listaServicios = doc.data().listaServicios;
                        me.datosVenta.fechaCreacion = doc.data().fechaCreacion;
                        me.datosVenta.fechaModificacion = doc.data().fechaModificacion;
                    });
            }

        },
        listarCliente() {
            let me = this;
            dbClientes.where("estado", 'in', [2, 3])
                .onSnapshot((querySnapshot) => {
                    me.listaClientes = [];
                    querySnapshot.forEach((doc) => {
                        me.listaClientes.push({
                            idCliente: doc.id,
                            razonSocial: doc.data().razonSocial,
                        });
                    });
                    me.listaClientes.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                });
        },
        listarServicios() {
            let me = this;
            dbServicios.where("estado", '==', 2)
                .onSnapshot((querySnapshot) => {
                    me.comboServicios = [];
                    querySnapshot.forEach((doc) => {
                        me.comboServicios.push({
                            idServicio: doc.id,
                            identificador: doc.data().identificador,
                            descripcion: doc.data().descripcion,
                            coste: doc.data().coste,
                        });
                    });
                    me.comboServicios.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                });
        },
        pasarDatos(row) {
            let me = this;
            row.item.coste = me.comboServicios.filter(x => x.idServicio == row.item.idServicio)[0].coste
            // (axios.defaults.headers.common[
            //     "Authorization"
            // ] = `Bearer ${localStorage.token}`);
            // axios
            //     .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/consultar-servicio", {
            //         params: {
            //             idServicio: row.item.idServicio,
            //         },
            //     })
            //     .then(function (response) {
            //         row.item.coste = response.data[0].coste;
            //     })
            //     .catch(function (error) {
            //         me.swat('error', 'Algo salió mal!')
            //     });
        },
        sumarTotal() {
            let me = this;
            let total = 0;
            for (let i in me.datosVenta.listaServicios.filter(x => x.estado == 2)) {
                total += parseFloat(me.datosVenta.listaServicios.filter(x => x.estado == 2)[i].importeVenta);
            }
            me.datosVenta.total = total.toFixed(2)

        },
        listarColaboradores() {
            let me = this;
            dbColaborador.where("estado", "==", 2)
                .onSnapshot((querySnapshot) => {
                    me.listaColaboradores = [];
                    querySnapshot.forEach((doc) => {
                        me.listaColaboradores.push({
                            idColaborador: doc.id,
                            nombres: doc.data().nombres,
                        });
                    });
                    me.listaColaboradores.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                });
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

    },
    computed: {
        computedPrincipalForm() {
            return this.$refs.observer;
        }
    },

    mounted() {
        let me = this;
        me.listarCliente();
        me.listarServicios();
        me.listarColaboradores();
        me.consultarVenta();
    }

}
</script>
